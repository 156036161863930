import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
    GatsbyImage, getImage
} from 'gatsby-plugin-image';
import LocalizedLink from './LocalizedLink';

import SecondaryButton from './SecondaryButton';

const TwoColumnsTitle = styled.h3`
    font-size: 24px;
    font-weight: 300;
    margin-bottom: 0px;
`;

const LayoutTwoColumns = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0px auto;
`;

const PictureObject = styled(GatsbyImage)`
    position: relative;
    z-index: 2;
    margin-left: 4%;

    @media (min-width: 320px) and (max-width: 768px) {
        margin-left: 0%;
    }
`;

const Video = styled.video`
    position: relative;
    z-index: 2;
    margin: 10% auto;
    max-width: 75%;


    @media (min-width: 320px) and (max-width: 768px) {
        max-width: 100%;
        margin: 5% auto;
    }
`;

const LeftCellText = styled.p`
    font-size: 14px;
    font-weight: 200;
    line-height: 21px;
    margin-bottom:5%;
`;

const LeftCell = styled.div`
    display: flex;
    flex-direction: column;
    width: 25%;
    padding-right: 3%;

    @media (min-width: 320px) and (max-width: 768px){
        width:100%;
    }
`;

const RightCell = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;

    @media (min-width: 320px) and (max-width: 768px){
        width: 100%;
    }
`;

const CellRow = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items: flex-start;
    margin-top: 3%;
    align-items: center;

    ${({ inverse }) => inverse ? `
      flex-direction: row-reverse;

      & ${LeftCell} {
        padding-right: 0%;
        padding-left: 3%;
      }
    ` : ''}

    @media (min-width: 320px) and (max-width: 768px){
        flex-direction:column;
        bottom: 50px;
    }
`;

const TwoColumnsPicture = ({
    title, text, titleButton, altDescription, inverse, linkUrl, video, pictureObject = null
}) => {

    return (
        <React.Fragment>
            <LayoutTwoColumns>
                <CellRow inverse={inverse}>

                    <LeftCell>
                        <TwoColumnsTitle
                            dangerouslySetInnerHTML={{ __html: title }}></TwoColumnsTitle>
                        <LeftCellText dangerouslySetInnerHTML={{ __html: text }}></LeftCellText>
                        <LocalizedLink to={linkUrl}>
                            <SecondaryButton titleButton={titleButton} />
                        </LocalizedLink>
                    </LeftCell>

                    <RightCell>
                        {pictureObject && <PictureObject
                            image={getImage(pictureObject)}
                            alt={altDescription} />}

                        {video && <Video autoPlay
                            loop
                            muted
                            playsInline
                            src={video} />}
                    </RightCell>
                </CellRow>
            </LayoutTwoColumns>
        </React.Fragment>
    );
};

TwoColumnsPicture.defaultProps = {
    inverse: false,
    video: ''
};

TwoColumnsPicture.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    titleButton: PropTypes.string,
    linkUrl: PropTypes.string,
    pictureObject: PropTypes.object,
    altDescription: PropTypes.string,
    video: PropTypes.string,
    inverse: PropTypes.bool
};

export default TwoColumnsPicture;
